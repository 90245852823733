import React, { useState } from "react"

import Layout from "../components/layout/layout"
import { Link } from "gatsby"
import Resource from "../components/resources/resource"

const SearchResults = ({ location }) => {
	const results = location?.state?.searchResults || []
	const query = location?.state?.search || ""

	return (
		<Layout>
			<h2 className="text-6xl font-bold m-0 md:ml-12">Search Results</h2>
			<div className="p-4 ">
				{results.length > 0 ? (
					<ul className="divide-y divide-gray-200">
						{results.map(result => (
							<li key={result.id} className="px-4 py-4 sm:px-0">
								<SearchItem item={result} />
							</li>
						))}
					</ul>
				) : (
					<p className="text-center">
						<ResultBold query={query} />
					</p>
				)}
			</div>
		</Layout>
	)
}

const ResultBold = ({ query }) => {
	if (query) {
		return (
			<span>
				No results for <strong>{query}</strong>
			</span>
		)
	}
	return <span>No results!</span>
}

const DateInformation = ({ productId, from, authorizedOn, createdDate }) => {
	if (createdDate) {
		return (
			<span>
				—{" "}
				{new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "long",
					day: "2-digit",
				}).format(new Date(createdDate))}
			</span>
		)
	}

	return productId?.length > 0 ? (
		<span>
			—{" "}
			{new Intl.DateTimeFormat("en-AU", {
				timeZone: "Australia/NSW",
				year: "numeric",
				month: "long",
				day: "2-digit",
			}).format(new Date(from))}
		</span>
	) : (
		<span>
			—{" "}
			{new Intl.DateTimeFormat("en-AU", {
				timeZone: "Australia/NSW",
				year: "numeric",
				month: "long",
				day: "2-digit",
			}).format(new Date(authorizedOn))}
		</span>
	)
}

const SearchItem = ({
	item: {
		permalink,
		name,
		excerpt,
		from,
		authorizedOn,
		file,
		fileName,
		createdDate,
		contentVisibility,
		eventId,
		abstract,
	},
}) => {
	const linkUrl = file
		? `/resources/${permalink}`
		: eventId?.length > 0
		? `/events/${permalink}`
		: `/news/${permalink}`
	// console.log(linkUrl)
	const type = file ? "Asset" : eventId?.length > 0 ? "Event" : "News"
	return (
		<div className="flex py-4 flex-col-reverse sm:flex-row ml-12">
			<div className="flex-grow">
				<h4 className="text-lg font-bold text-blue-500">
					<Link to={linkUrl}>{name}</Link>
				</h4>

				{!file ? (
					<div
						dangerouslySetInnerHTML={{
							__html: excerpt.slice(0, 250 || excerpt.length),
						}}
					></div>
				) : (
					<div
						dangerouslySetInnerHTML={{
							__html: abstract?.slice(0, 250 || abstract.length),
						}}
					></div>
				)}
				<p className="py-1 text-xs text-sm font-semibold">
					{type}{" "}
					<DateInformation
						productId={eventId}
						from={from}
						authorizedOn={authorizedOn}
						createdDate={createdDate}
					/>
				</p>
			</div>
		</div>
	)
}

export default SearchResults
